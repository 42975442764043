import React,{useState,useEffect} from "react";
import OrderHeaderBar from "../Layout/OrderHeaderBar";
import { CiEdit } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { setOpenCustDetailsState, setPopOpenState } from "../../store/slices/customSlice";
import { fetchUserAllCustomers } from "../../service/userCustomerDetailsService";
import { RootState } from "../../store/store";

type CustomerDetailsVal = {
    id:number,
  type: string;
  value: string;
};

const CustomerDetails: React.FC = () => {
  const dispatch = useDispatch()
  //const user_details = localStorage.getItem('user_details')
  const user_details = useSelector(
    (state: RootState) => state.login?.userDetails
  );
  const userDetails: CustomerDetailsVal[] = [
    {
    id:1,
    type: "Name",
    value:  user_details.first_name,
  },
  {
    id:2,
    type: "Gender",
    value: user_details.gender,
  },
  {
    id:3,
    type:"Contact",
    value: user_details.phone_number,
  }

]

  return (
    <section className="">
      {/* <OrderHeaderBar title="Customer Details">
        <CiEdit className="ml-2 text-DARKEST_GREY_L2 text-xl  cursor-pointer " onClick={()=>{
          
    
          
          dispatch(setOpenCustDetailsState(true))
          dispatch(setPopOpenState(true))
        }} />
      </OrderHeaderBar> */}
      <div className="px-3 lg:px-10 pt-3  lg:pt-4">
        {userDetails.map((item,key)=>(
            <div className="text-DARKEST_GREY_L1 flex items-center lg: pb-2 gap-8">
                <h2 className="w-14">
                    {item.type}
                </h2>
                <p className="font-medium">
                    {item.value}
                </p>
            </div>
        ))}
      </div>
    </section>
  );
};

export default CustomerDetails;