/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import SalonProfile from "../pages/SalonProfile";
import OrderDetails from "../pages/OrderDetails";
import Login from "../components/Auth/Login";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import store, { AppDispatch, RootState } from "../store/store";
import {
  setLoginState,
  setUserAuth,
  setUserDetails,
  setUserName,
} from "../store/slices/authSlice";

import { IinitialUsertype } from "../types/userAuthTypes";
import ServiceReview from "../pages/ServiceReview";
import { Loader } from "../components/Ui/Loader";
import { setPopOpenState } from "../store/slices/customSlice";
import { setShopId, setSource } from "../store/slices/salonDetailsSlice";
import { extractShopIdFromUrl } from "../utils/salonProfile";
import { getUserDetails } from "../service/userAuthService";
import TermsAndConditions from "../pages/TermsAndConditions";
import Privacy from "../pages/Privacy";
import HelpAndSupport from "../pages/HelpAndSupport";
import { fetchUserAllCustomers } from "../service/userCustomerDetailsService";
import HomeLanding from "../pages/HomeLanding";
import { fetchUserWallet } from "../service/userCustomerDetailsService";
import axios from "axios";
const AppRoutes: React.FC = () => {
  const dispatch: AppDispatch = store.dispatch;
  const user_details = useSelector(
    (state: RootState) => state.login?.userDetails
  );
  const user_id = user_details?.user_id;
  const { isLoginTrue } = useSelector((state: RootState) => state.login);
  const isPopOpen = useSelector(
    (state: RootState) => state.custom.openSharePop
  );
  const shopId = extractShopIdFromUrl();
  const jwt_token = localStorage.getItem("auth_tokens");
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<
    boolean | string
  >(localStorage.getItem("is_loggedin_") === "true");
  const [showLoader1, setShowLoader1] = useState<boolean>(false);
  const [JWT_TOKEN, setJWT_TOKEN] = useState<{ accessToken: string }>({
    accessToken: "",
  });
  useEffect(() => {
    dispatch(setShopId(shopId ? `${shopId}` : ""));
    const url = new URL(window.location.href);
    const source = url.searchParams.get("source");
    if (source) {
      dispatch(setSource(source));
    }
  }, [shopId]);

  useEffect(() => {
    const isUserLoggedin = localStorage.getItem("is_loggedin_") === "true";
    setIsUserAuthenticated(isUserLoggedin);
  }, []);

  useEffect(() => {
    if (jwt_token && jwt_token) {
      setJWT_TOKEN({ accessToken: jwt_token });
    }
  }, [jwt_token]);
  useEffect(() => {
    fetchUserWallet(dispatch, user_id);
  }, [dispatch, user_id,shopId]);

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(setUserAuth(true));
    }
  }, [isUserAuthenticated, showLoader1]);

  useEffect(() => {
    if (isPopOpen) {
      document.body.classList.add("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isPopOpen]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeLanding />} />
        {/* ------------ shop Routs ---------- */}
        <Route
          path="/:shopName/:city/salonProfile"
          element={<SalonProfile />}
        />
        <Route
          path="/:shopName/:city/orderDetails"
          element={<OrderDetails />}
        />
        <Route path="/:shopName/:city/review" element={<ServiceReview />} />

        {/* --------- Custom Route--------------- */}
        <Route path="/termsandcondition" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/helpandsupport" element={<HelpAndSupport />} />
      </Routes>
      <div
        className={` ${
          isLoginTrue && !showLoader1 ? "fixed" : "hidden "
        }   inset-0  flex items-center justify-center bg-opacity-50 backdrop-filter backdrop-blur-lg   bg-[#29292b81] bg-blend-saturation h-screen w-full 2xl:w-[1700px]  z-10 `}
      >
        <Login />
      </div>
      {/* <div
        className={` ${
          showLoader1 ? "fixed  z-50" : "hidden "
        }   inset-0  flex items-center justify-center bg-opacity-50 backdrop-filter backdrop-blur-lg   bg-[#29292b81] bg-blend-saturation h-screen w-screen 2xl:w-[1700px]  `}
      >
        <Loader />
      </div> */}
    </>
  );
};

export default AppRoutes;
