import React,{FormEvent, useEffect, useState} from 'react';
import { LuDot } from "react-icons/lu";
import { Link } from 'react-router-dom';
import Cart from './Cart';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { formatTime } from '../../utils/validations';
import { setPopOpenState } from '../../store/slices/customSlice';
import { IoClose } from 'react-icons/io5';
import { AddupPriceMobile } from "./cart/AddupPrice";
import { MdCelebration } from 'react-icons/md';

interface ICartProps {
    summary: {
      title: string,
      showCoupon: Boolean,
      ctaName: string,
      onclick: () => void
    }
  }
  
interface Offer {
  cart_value: number;
  offer_percentage: number;
}  

const MobileCart :React.FC<ICartProps> = ({summary})=> {
    const dispatch = useDispatch();
    const cartValue = useSelector((state: RootState) => state.userCart);

    const [showDetails, setShowDetails] = useState<boolean>(false)
    const [amountToAdd, setAmountToAdd] = useState<number | null>(null);
    const [nearestOffer, setNearestOffer] = useState<Offer | null>(null);
    const [discountedTotal, setDiscountedTotal] = useState<number>(
      cartValue.total
    );
    // const cartValue = useSelector((state: RootState) => state.userCart);
    const isPopActive = useSelector((state:RootState)=>state.custom.isPopOpen)
    const isUserAuthenticated = useSelector((state:RootState)=>state.login.userAuthenticated)
    const offers = useSelector(
      (state: RootState) => state.salonOffers.offers || []
    );

    const [offerapplied, setOfferapplied] = useState<Offer | null>(null);
    useEffect(() => {
      findNearestOffer();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartValue.total, offers]);

    const findNearestOffer = () => {
      if (offers.length === 0) return;
      const sortedOffers = [...offers].sort(
        (a, b) => a.cart_value - b.cart_value
      );
      if (cartValue.total < sortedOffers[0].cart_value) {
        setNearestOffer(sortedOffers[0]);
        setAmountToAdd(sortedOffers[0].cart_value - cartValue.total);
        setDiscountedTotal(cartValue.total);
        // setTotalCartAmount(cartValue.total);
        return;
      }
      const maxOffer = sortedOffers[sortedOffers.length - 1];
      if (cartValue.total >= maxOffer.cart_value) {
        applyDiscount(maxOffer);
        setNearestOffer(null);
        setAmountToAdd(null);
        return;
      }
      const eligibleOffer = [...sortedOffers]
        .reverse()
        .find((offer) => cartValue.total >= offer.cart_value);
      const nextOffer = sortedOffers.find(
        (offer) => cartValue.total < offer.cart_value
      );
  
      if (eligibleOffer) {
        applyDiscount(eligibleOffer);
        setNearestOffer(nextOffer ?? null);
        setAmountToAdd(nextOffer ? nextOffer.cart_value - cartValue.total : null);
      }
    };
    
    const applyDiscount = (offer: Offer) => {
      setOfferapplied(offer);
      const discountAmount = (cartValue.total / 100) * offer.offer_percentage;
      setDiscountedTotal(cartValue.total - discountAmount);
      // setTotalCartAmount(cartValue.total - discountAmount);
    };

    const handlePop = (e:FormEvent) =>{
        e.preventDefault()
        setShowDetails(!showDetails)
        dispatch(setPopOpenState(!isPopActive))
    }
    return (
      <div>
        {/* <div className="">
          {amountToAdd !== null && (
            <AddupPriceMobile
              disPercentage={nearestOffer?.offer_percentage || 0}
              price={amountToAdd}
            />
          )}
        </div>
        {discountedTotal < cartValue.total && (
          <div className=" border-t border-b border-green bg-[#F1FFF1] py-2.5 absolute w-full left-0 h-10 flex gap-2 px-2 items-center justify-center">
            <MdCelebration className="w-[17.19px] h-[16.31px] lg:w-[20.62px] lg:h-[19.57px]" />
            <p className="font-semibold text-xs lg:text-sm text-center">
              Congratulations! ₹
              {cartValue.total - discountedTotal + cartValue.totalDiscount}{" "}
              saved so far.
            </p>
          </div>
        )} */}
        
        {/* <div className="">
        
          {amountToAdd !== null && (
            <div>
              <AddupPriceMobile
                disPercentage={nearestOffer?.offer_percentage || 0}
                price={amountToAdd}
              />

              
              {discountedTotal < cartValue.total && (
                <div className="border-t border-b border-green bg-[#F1FFF1] py-2.5 absolute w-full left-0 h-10 flex gap-2 px-2 items-center justify-center">
                  <MdCelebration className="w-[17.19px] h-[16.31px] lg:w-[20.62px] lg:h-[19.57px]" />
                  <p className="font-semibold text-xs lg:text-sm text-center">
                    Congratulations! ₹
                    {cartValue.total - discountedTotal + cartValue.totalDiscount} saved so far.
                  </p>
                </div>
              )}
            </div>
        )}
        </div> */}
        
      {/* Render AddupPriceMobile and the Congratulations message together */}
      {amountToAdd !== null && (
        <div className="relative">
          {/* AddupPriceMobile Component */}
          <AddupPriceMobile
            disPercentage={nearestOffer?.offer_percentage || 0}
            price={amountToAdd}
          />
        </div>
      )}

      {/* Always render the Congratulations Message, even if AddupPrice is hidden */}
      {discountedTotal < cartValue.total && (
        <div className="border-t border-b border-green bg-[#F1FFF1] py-2.5 w-full h-10 flex gap-2 px-2 items-center justify-center">
          <MdCelebration className="w-[17.19px] h-[16.31px] lg:w-[20.62px] lg:h-[19.57px]" />
          <p className="font-semibold text-xs lg:text-sm text-center">
            Congratulations! ₹
            {cartValue.total - discountedTotal + cartValue.totalDiscount} saved so far.
          </p>
        </div>
      )}

        <div className=" z-50 w-full gradBorder h- relative bg-white flex items-center p-4 rounded-xl">
      

        <div className={`${!showDetails&&'hidden'} fixed top-0 left-0 h-full flex flex-col justify-center items-center f  bottom- 80 bg-opacity-50 backdrop-filter backdrop-blur-md   bg-[#29292b81] bg-blend-saturation px-7 `}>
            <IoClose className='text-2xl bg-white w-7 h-7  p-1 mb-2 rounded-full' onClick={handlePop}/>
          <Cart   summary={summary} />
        </div>
                 <div className="">
              <h2 className="text-blue font-semibold">
              ₹{cartValue.total}
              </h2>
              <div className="text-blue flex  items-center text-xs">
                  <h4 className="">
                 {cartValue.totalService} service 
                  </h4>
                  <LuDot/>
                  <h4 className="">
                {formatTime(+cartValue.totaltime)}
                  </h4>
              </div>
              <button className="text-xs text-DARKEST_GREY_L1" onClick={handlePop}>
              View Details
              </button>
          </div>
          <div className="flex flex-1"/> 
          {/* <Link to='/lucas/hyderabad/orderDetails'> */}
          <button className={`${isUserAuthenticated?'bg-black':'bg-[#19191896]'}  text-white px-7 py-2 font-medium rounded-md text-xs`} 
          disabled={!isUserAuthenticated}
          onClick={summary.onclick}>
          Continue
          </button>
          {/* </Link> */}
      </div>
    </div>
    )
}

export default MobileCart;