import React , {useState,useEffect} from 'react';

interface CheckboxProps {
    label?: string;
    onChange?: (checked: boolean) => void;
  }
  
  const Checkbox: React.FC<CheckboxProps> = ({ label, onChange }) => {
    const [checked, setChecked] = useState(false);
  
    // const handleCheckboxChange = () => {
    //   setIsChecked(!isChecked);
    //   onChange(!isChecked);
    // };
  
    return (
        <div
        className={`w-4 h-3.5 lg:w-4 lg:h-4 lg:p-1 flex items-center justify-center border-2 rounded-sm cursor-pointer transition-colors duration-300 ${
            checked ? 'bg-texthero border-texthero' : 'bg-white border-[#D1D5DB]'
        }`}
        onClick={() => setChecked(!checked)}
    >
        {checked && (
            <svg
                className="w-full h-full text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M5 12l5 5L20 7" />
            </svg>
        )}
    </div>
    );
  };
  
export default Checkbox;  
