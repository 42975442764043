import React from 'react';
import { CiDiscount1 } from "react-icons/ci";

interface IAddupProp {
    price?:number|null,
    disPercentage? : number|null
}
const AddupPrice :React.FC<IAddupProp> = ({price,disPercentage})=>{
    return (
            <div className=" flex items-center border border-blue bg-[#E9F2FF] rounded-[4px] px-4 py-2 w-[280px] h-[60px] lg:w-[386px] lg:h-[60px] lg:gap-2 gap-2">
                <CiDiscount1 className='text-blue text-2xl w-5 h-5'/>
            <h4 className="text-blue lg:text-sm text-xs font-semibold">
            Add services worth ₹{price} more to avail {disPercentage}% off
            </h4>

        </div>
    )
}

export { AddupPrice };

interface IAddupMobProp {
    price?:number|null,
    disPercentage? : number|null
}

const AddupPriceMobile :React.FC<IAddupMobProp> = ({price,disPercentage})=>{
    return (
            <div className=" flex items-center border border-blue bg-[#E9F2FF] rounded-[4px] px-4 py-2 w-[370px] h-[60px] lg:w-[386px] lg:h-[60px] lg:gap-2 gap-2">
                <CiDiscount1 className='text-blue text-2xl w-5 h-5'/>
            <h4 className="text-blue lg:text-sm text-xs font-semibold">
            Add services worth ₹{price} more to avail {disPercentage}% off
            </h4>

        </div>
    )
}

export  { AddupPriceMobile };