import React, { useState, useRef,ReactNode } from 'react';
import { getSalonCategories } from '../../lib/salonServicesService';
import { getBrandColor } from '../../lib/brandService';
import { FaChevronLeft,FaChevronRight  } from "react-icons/fa6";

interface ICarouselComponentProps {
  children: ReactNode;
  custClassName?:string;
  isCustCls?:boolean;
  scrollVal?:number
 
}

const Carousel: React.FunctionComponent<ICarouselComponentProps> = ({children,custClassName='right-0 items-center',isCustCls=false,scrollVal=200}) => {
    const [activeCatlog, setActiveCatlog] = useState<string>('Recommended');
    const salonCatelogs = getSalonCategories();
    const brandColor = getBrandColor();
    
    // Ref for the scrollable container
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -scrollVal, // Adjust the value as needed
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: scrollVal, // Adjust the value as needed
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className={` ${isCustCls?'':'relative'}  flex items-center overflow-hidden bg-white `}>
            <div 
                className="flex items-center gap-3 overflow-auto hideScrollBar w-full md:pr-[100px] lg:w-[696px]" // Increased padding-right to ensure space for buttons
                ref={scrollContainerRef}
            >
            {children}
            </div>
            

            {/* Wrapper for Arrow Buttons */}
        

            <div className={`${custClassName} hidden md:flex absolute  m-auto justify-center   w-20 h-full  gap-2   bg-white lg:w-[77px] lg:h-8`} style={{
              color:isCustCls?brandColor:'text-[#1C1B1F]'
            }}>
              
                <FaChevronLeft 
                onClick={scrollLeft}
                />
                <FaChevronRight
                  onClick={scrollRight}
                  />
                  </div>
                
           
        </div>
    );
};

export default Carousel;
