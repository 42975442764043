import React, { useState, useEffect } from "react";

import img1 from "../../assets/booking/status1.png";
import BookingStatusCard from "../Ui/BookingStatusCard";

import { useDispatch, useSelector } from "react-redux";
import { gql, useMutation } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import {
  setBookingFlow,
  setBookingState,
} from "../../store/slices/bookingSlice";
import { bookingStateValues } from "../../config/parameters";
import { CREATE_BOOKING_MUTATION } from "../../service/mutations/createBookingMutation";
import { extractShopIdFromUrl } from "../../utils/salonProfile";
import { RootState } from "../../store/store";
import { convertTo24Hour } from "../../utils/slotSchedule";
import { useParams } from "react-router-dom";
import { UPDATE_WALLET_MUTATION } from "../../service/userCustomerDetailsService";
import { setWallet } from "../../store/slices/userWalletSlice";
const ConfirmBooking: React.FC = () => {
  const dispatch = useDispatch();
  const [createBooking] = useMutation(CREATE_BOOKING_MUTATION);
  const salonId = extractShopIdFromUrl();
  const professional = useSelector(
    (state: RootState) => state.booking.selectedProfessionId
  );
  const customerId = useSelector(
    (state: RootState) => state.booking.customerId
  );
  const selected_timings = useSelector(
    (state: RootState) => state.booking.selectedTime
  );
  const { selectedDate } = useSelector((state: RootState) => state.booking);
  const totalPrice = useSelector((state: RootState) => state.userCart.total);
  const service_Ids = useSelector(
    (state: RootState) => state.userCart.addedServices
  );
  const [updateWallet] = useMutation(UPDATE_WALLET_MUTATION);
  const walletbalance = useSelector((state: RootState) => state.wallet.balance);
  const user_details = useSelector(
    (state: RootState) => state.login?.userDetails
  );
  const source = useSelector((state: RootState) => state.salonDetails.source);
  console.log("booking source", source);
  const user_id = user_details?.user_id;
  const subCategoryIds = service_Ids
    .filter((service) => service.cartType === "service")
    .map((service) => service.id);

  const packageIds = service_Ids
    .filter((service) => service.cartType === "package")
    .map((service) => service.id);

  const btnActions: Array<{
    id: number;
    btnName: string;
    btnAction: () => void;
    custClassName?: string;
  }> = [
    {
      id: 1,
      btnName: "Review Booking",
      btnAction: () => {
        dispatch(setBookingState(""));
        dispatch(setBookingFlow(false));
      },
      custClassName: "bg-white text-texthero",
    },
    {
      id: 2,
      btnName: "Confirm Booking",
      btnAction: async () => {
        dispatch(setBookingState(bookingStateValues[1]));

        try {
          const bookingResponse = await createBooking({
            variables: {
              shopId: salonId,
              professionalId: professional?.toString(),
              userId: user_id,
              bookingCustomerDetailId: user_id,
              slotTimings: selected_timings.map((slot_time) =>
                convertTo24Hour(slot_time)
              ),
              appointmentDate: selectedDate,
              cost: totalPrice,
              status: "accepted",
              paymentStatus: "Not Paid",
              servicesIds: subCategoryIds,
              packagesIds: packageIds,
              booking_source: source,
            },
          });

          // Check if booking was successful
          if (bookingResponse?.data?.createBooking) {
            console.log("Booking created successfully");
            const walletResponse = await updateWallet({
              variables: {
                user_id: user_id,
                amount: walletbalance,
              },
            });
            if (walletResponse?.data?.updateWallet) {
              const updatedBalance = walletResponse.data.updateWallet.balance;
              dispatch(
                setWallet({
                  balance: updatedBalance,
                })
              );
              toast("Wallet balance updated successfully");
            }
          }
        } catch (error) {
          console.error("Error during booking or wallet update:", error);
          toast.error("An error occurred during booking. Please try again.");
        }
      },
      custClassName: "bg-texthero text-white",
    },
  ];

  const propValue = {
    title: " Confirm Booking",
    description: " Are you sure you want to confirm your   booking?",
    btnActions: btnActions,
    imgUrl: img1,
  };

  return (
    <>
      <div className="w-full z-[999] absolute top-0">
        <ToastContainer className="toast-container" />
      </div>
      <section className=" ">
        <BookingStatusCard statusObj={propValue} />
      </section>
    </>
  );
};

export default ConfirmBooking;
