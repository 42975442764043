import * as React from "react";
import NavBar from "../components/Layout/NavBar";
import Footer from "../components/Layout/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import CustServicePopUpCard from "../components/Ui/CustServicePopUpCard";
import { ClipLoader } from "react-spinners";
import { extractShopIdFromUrl } from "../utils/salonProfile";
import { useEffect } from "react";
import axios from "axios";
const SalonServiceMain = React.lazy(() => import("../components/SalonServices/_SalonServiceMain"));
const SalonProfileMain = React.lazy(() => import("../components/SalonProfile/_SalonProfileMain"));

const Spinner: React.FC = () => (
  <div className="flex justify-center items-center min-h-screen">
    {/* Use ClipLoader for a smooth spinner */}
    <ClipLoader color="#1E40AF" loading={true} size={50} />
  </div>
);



export interface IAppProps {}

const SalonProfile: React.FC<IAppProps> = () => {
  const isLoginPop = useSelector((state: RootState) => state.login.isLoginTrue);
  let { isProfileCartOpen } = useSelector((state: RootState) => state.custom);
  let isCustServPop = useSelector(
    (state: RootState) => state.custom.openCustServicePop
  );

  const user_details = useSelector(
    (state: RootState) => state.login?.userDetails
  );
  const user_id = user_details?.user_id;
  const shopId = extractShopIdFromUrl();
  const jwt_token = localStorage.getItem("auth_tokens");
  useEffect(() => {
    const sendWelcomeMessage = async () => {
      try {
        const response = await axios.post(
          "https://devapi.stylin.in/user/sendWelcomeMessage",
          {
            shopId: shopId,
            userId: user_id, 
          },
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt_token}`,
            },
          }
        );

        console.log("Response:", response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    sendWelcomeMessage();
  },[jwt_token, shopId, user_id]);

  return (
    <React.Fragment>
      <main className="">
        <NavBar />
        <section
          className={`${isLoginPop && "-z-30 "} ${
            isProfileCartOpen ? "-z-40" : ""
          } layout layout-md layout-lg layout-xl pb-80 relative`}
        >
          {/* --------- Salon Profile --------- */}
          {isCustServPop && <CustServicePopUpCard />}

          {/* Use Suspense to Lazy Load Components */}
          <React.Suspense fallback={<Spinner />}>
            <SalonProfileMain />
          </React.Suspense>

          {/* ------------- Salon Services ----------- */}
          <section className="pt-5 md:pt-10 lg:pt-14 w-full">
            <React.Suspense fallback={<Spinner />}>
              <SalonServiceMain />
            </React.Suspense>
          </section>
        </section>
        <Footer />
      </main>
    </React.Fragment>
  );
};

export default SalonProfile;
