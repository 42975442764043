import React, { useState, useEffect } from "react";
import Carousel from "../Carousel/Carousel";
import SlotDateCard from "../Ui/SlotDateCard";
import SlotTimeCard from "../Ui/SlotTimeCard";
import OrderHeaderBar from "../Layout/OrderHeaderBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { GetAllSlots } from "../../types/bookingFlowTypes";
import { getDateAndDay, getMonthAndYear } from "../../utils/slotSchedule";
import PopUpLayout from "../Layout/PopUpLayout";
const TimeSchedule: React.FC = () => {
  const monthAndYearList = getMonthAndYear();
  const dateAndDayList = getDateAndDay();

  const getSlots = useSelector(
    (state: RootState) => state.salonSlots.availableSlots
  );
  const noSlotsMessage = useSelector(
    (state: RootState) => state.salonSlots.noSlotsMessage
  );
  const otherProfessionals = useSelector(
    (state: RootState) => state.salonSlots.otherProfessionals
  );
  const selectedDate = useSelector(
    (state: RootState) => state.booking.selectedDate
  );

  const selectedProfessional = useSelector(
    (state: RootState) => state.booking.selectedProfessional
  );  // New state for selected professional

  const [fetchedSlots, setFetchedSlots] = useState<GetAllSlots[]>([]);
  const [updatedSlots, setUpdatedSlots] = useState<GetAllSlots[]>([]);
  const [isSlotsInTime, setIsSlotsInTime] = useState<boolean>(true);

  const currentDate = new Date().toLocaleDateString();

  const [year, month, day] = selectedDate
    .split("-")
    .map((x) => x.replace(/^0/, "")); // remove leading zero
  const formattedSelectedDate = `${day}/${month}/${year}`; // format the selected date

  useEffect(() => {
    const currentTime = new Date().toLocaleTimeString(); // get the current time

    const filteredSlots = fetchedSlots.map((slot) => ({
      ...slot,
      morning_shift: slot.morning_shift.filter(
        (timeSlot) => timeSlot.slot_time >= currentTime
      ),
      evening_shift: slot.evening_shift.filter(
        (timeSlot) => timeSlot.slot_time >= currentTime
      ),
    }));

    if (currentDate === formattedSelectedDate) {
      setUpdatedSlots(filteredSlots);
      if (fetchedSlots.length > 0) {
        const morningFirstSlotTime =
          fetchedSlots && fetchedSlots[0].morning_shift[0].slot_time;
        const eveningLastSlotTime =
          fetchedSlots[0].evening_shift[
            fetchedSlots[0].evening_shift.length - 1
          ].slot_time;
        if (
          morningFirstSlotTime < currentTime &&
          eveningLastSlotTime > currentTime
        ) {
          setIsSlotsInTime(false);
        } else {
          setIsSlotsInTime(true);
        }
      }
    } else {
      setUpdatedSlots(fetchedSlots);
      setIsSlotsInTime(true);
    }
  }, [currentDate, fetchedSlots, formattedSelectedDate]);

  console.log("fetched slots", fetchedSlots);
  useEffect(() => {
    setFetchedSlots(getSlots);
  }, [getSlots]);

  return (
    <section className="  ">
      <OrderHeaderBar title="Select Schedule" />

      <div className="relative pt-3 lg:pt-5  px-3 lg:px-10 ">
        <div className="flex items-center gap-1 text-texthero lg:text-lg font-medium lg:pb-3">
          <h3 className="">{monthAndYearList.month}</h3>
          <h4 className="">{monthAndYearList.year}</h4>
        </div>

        <Carousel
          scrollVal={600}
          isCustCls={true}
          custClassName="top-4 right-0 "
        >
          {dateAndDayList.map((slot, key) => (
            <div
              className={` relative flex flex-col items-center justify-end  `}
              key={key}
            >
              <SlotDateCard isSlotsAvailable={isSlotsInTime} slotDates={slot} />
            </div>
          ))}
        </Carousel>
      </div>

      {/* Show "Select Professional" message if no professional is selected */}
      {selectedDate &&  !selectedProfessional && (
        <div className="pt-10 px-2 lg:px-10">
          <h2 className="text-DARKEST_GREY_L1 font-medium">
            Please select a professional first
          </h2>
        </div>
      )}

      {/* Show available slots only if a professional is selected */}
      {selectedProfessional && (

      <>   
      {/* Show the slots only if a professional is selected */}
      {noSlotsMessage && (
        <div className="pt-10 px-2 lg:px-10">
          <h2 className="text-DARKEST_GREY_L1 font-medium">{noSlotsMessage}</h2>
          <div className="mt-5">
            <h3 className="text-DARKEST_GREY_L1">Other Professionals:</h3>
            <ul>
              {otherProfessionals?.map((professional) => (
                <li
                  key={professional.professional_id}
                  className="text-DARKEST_GREY_L2"
                >
                  {professional.first_name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className={`${selectedDate == "" && "hidden"}`}>
        <div className="pt-12 px-2 lg:px-10 ">
          <h2 className="text-DARKEST_GREY_L1 font-medium">Morning Shift</h2>
          <div className="grid grid-cols-3 gap-3 lg:gap-0 lg:grid-cols-4">
            {updatedSlots.length > 0 &&
              updatedSlots.map((slot, key) => (
                <SlotTimeCard key={key} slots={slot.morning_shift ?? []} />
              ))}
          </div>
        </div>

        <div className="pt-10 lg:pt-12 px-2 lg:px-10 ">
          <h2 className="text-DARKEST_GREY_L1 font-medium">Afternoon Shift</h2>
          <div className="grid grid-cols-3 gap-3 lg:gap-0 lg:grid-cols-4">
            {updatedSlots.length > 0 &&
              updatedSlots.map((slot, key) => (
                <SlotTimeCard key={key} slots={slot.evening_shift ?? []} />
              ))}
          </div>
        </div>
      </div>
      </>
      )}
    </section>
  );
};

export default TimeSchedule;
