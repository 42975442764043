import React, { useEffect, useState } from "react";
import SelectProfessional from "./SelectProfessional";
import TimeSchedule from "./TimeSchedule";
import SelectPayment from "./SelectPayment";
import OrderNotes from "./OrderNotes";
import CustomerDetails from "./CustomerDetails";
import store, { AppDispatch, RootState } from "../../store/store";
import { fetchShopProfessionals } from "../../service/salonProfessionalService";
import { useDispatch, useSelector } from "react-redux";
import CustDetailsPopUpCard from "../Ui/CustDetailsPopUpCard";
import { fetchUserAllCustomers } from "../../service/userCustomerDetailsService";
import { extractShopIdFromUrl } from "../../utils/salonProfile";
import PopUpLayout from "../Layout/PopUpLayout";
import { IoClose } from "react-icons/io5";
import { setPopOpenState } from "../../store/slices/customSlice";
import {
  setCustomerIdState,
  setSlotErrorState,
} from "../../store/slices/bookingSlice";

const OrderDetailsMain: React.FC = () => {
  const dispatch = useDispatch();
  const shopId = extractShopIdFromUrl();
  useEffect(() => {
    const dispatch: AppDispatch = store.dispatch;

    fetchShopProfessionals(dispatch, shopId);
    //fetchUserAllCustomers(dispatch, user_values.user_id);
  }, [shopId]);
  const { slotError } = useSelector((state: RootState) => state.booking);
  const isPopOpen = useSelector((state: RootState) => state.custom.isPopOpen);

  const professional = useSelector(
    (state: RootState) => state.booking.selectedProfessionId
  );
 
  const { selectedDate } = useSelector((state: RootState) => state.booking);
  const selected_timings = useSelector(
    (state: RootState) => state.booking.selectedTime
  );
  const customerId = useSelector(
    (state: RootState) => state.booking.customerId
  );
  console.log("value for the order details page ", selected_timings.length > 0);

  const salonProfessionals = useSelector(
    (state: RootState) => state.salonProfessionals.professionals
  );
  console.log("fetched professionals", salonProfessionals);
  const userAllCustomer = useSelector(
    (state: RootState) => state.userAllCustomerDetails.customers
  );
  const openCustDetailPop = useSelector(
    (state: RootState) => state.custom.openCustDetailsPop
  );

  let [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (professional && professional > 0) {
      setCurrentStep(1);
    }
    if (selectedDate !== "") {
      setCurrentStep(2);
    }
    if (selected_timings.length > 0) {
      setCurrentStep(3);
    }
  }, [professional, selectedDate, selected_timings]);

  console.log("user all customer ", customerId);

  useEffect(() => {
    if (Array.isArray(userAllCustomer) && userAllCustomer.length > 0) {
      dispatch(
        setCustomerIdState(+userAllCustomer[0].booking_customer_detail_id)
      );
    }
  }, [userAllCustomer]);


  return (
    <main className="">
      <div
        className={`${
          slotError == "" ? "hidden" : " z-50"
        } fixed left-0 top-0 h-screen w-screen flex flex-col justify-center items-center lg:`}
      >
        <PopUpLayout>
          <div className="relative bg-white rounded-lg text-texthero flex flex-col items-center text-center justify-center h-full mx-4  md:m-auto  md:px-7 pt-8 font-medium md:pt-14 pb-8 ">
            <IoClose
              className="lg:text-2xl absolute top-3 cursor-pointer  "
              onClick={() => {
                dispatch(setPopOpenState(false));
                dispatch(setSlotErrorState(""));
              }}
            />
            {slotError}
          </div>
        </PopUpLayout>
      </div>
      <div
        className={`${
          openCustDetailPop
            ? "fixed left-0 top-0 z-50 w-screen h-screen"
            : "hidden"
        }  `}
      >
        <CustDetailsPopUpCard custDetails={userAllCustomer} />
      </div>
      <div className=" pb-8  w-full hideScrollBar overflow-scroll">
        <SelectProfessional salonProfessionalsData={salonProfessionals ?? []} />
      </div>
      <div className={`${!(currentStep > 0) && "hi dden"} pb-12 lg:pb-8`}>
        <TimeSchedule />
      </div>
      <div className={` ${!(currentStep > 1) && "hidden"} pb-12 lg:pb-8`}>
        <SelectPayment />
      </div>
      {/* <OrderNotes /> */}
      <div className={` ${!(currentStep > 2) && "hidden"}`}>
        <CustomerDetails />
      </div>
    </main>
  );
};

export default OrderDetailsMain;
